
.capitol-insights-trial-form {
    select {
        height: 37.33px;
        color: rgb(117, 117, 117);
    }

    label {
        font-size: 15px;
        font-family: GraphikRegular;
        color: #57626a
    }

    .title {
        text-align: center;
        color: #002b5a;
    }

    .form-container {
        width: 90%;
        margin: 0 auto;
    }

    .form-content {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
        margin-bottom: 32px;
    }

    .input-container {
        display: flex;
        flex-direction: column;
    }

    .input-field {
        border: 1px solid #dee2e6;
        border-radius: 0.375rem;
        padding: .375rem .75rem;
        color: rgb(117, 117, 117);
        font-weight: 450;
    }

    .input-field:focus {
        outline: none;
        border-color: #9ec5fe;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

    .input-field.invalid {
        border-color: #dc3545;
    }
    
    .input-field.invalid:focus {
        box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
        outline: none;
    }
 
    .error-text {
        color: #dc3545;
        font-size: 14px;
        font-weight: 500;
        padding-top: 2px;
    }

    .terms {
        font-family: GraphikRegular;
        font-size: 12px;
        color: #57626A;
    }

    .button-container {
        text-align: center;
    }

    .submit-button {
        text-align: center;
        padding: 12px 48px;
        border-radius: 8px;
        border: none;
        color: #fff;
        font-weight: 500;
    }

    .submit-button.disabled {
        background-color: gray;
        cursor: not-allowed; 
    }

    .submit-button.enabled {
        background-color: #007BB8;
        cursor: pointer; 
    }

    .submit-button.enabled:hover{
        background-color: #002B5A;
    }

    .confirmation-text {
        text-align: center;
    }

    .verification-text-container, .confirmation-content-container {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
        gap: 20px;
    }
}
.confirmation-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .confirmation-content {
        background-color: #002b5a;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
    }

    .confirmation-content .logo {
        color: #fff;
        float: right;
        height: 35px;
        max-width: 100%;
        width: 125px;
    }

    .title {
        color: rgb(0, 122, 185);
        text-align: center;
    }

    .confirmation-text {
        text-align: center;
    }
}