.highcharts-tooltip {
    table {
      font-family: 'GraphikRegular';
      font-size: 12px;
      color: #57626a;
    }

    table th {
      border-bottom: 1px solid #4375BB;
      color: #007BB8
    }

    table td .leftPadding {
      padding-left: 24px;
    }

    table td .rightPadding {
      padding-right: 24px;
    }

    table td div {
      padding-top: 12px;
      padding-right: 12px;
    }

    table td div:last-child {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    table td div span {
      padding-right: 12px;
    }

    table td div span:last-child {
      padding-right: 0px;
    }

    table th div {
      padding: 12px 24px;
      display: block;
    }

    table td span .dot {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .highcharts-xaxis-labels div {
    font-family: 'GraphikRegular';
    font-size: 12px;
    color: #808080;
    line-height: 18px;
  }

  .highcharts-yaxis-labels div {
    font-family: 'GraphikRegular';
    font-size: 12px;
    color: #808080;
    line-height: 20px;
    padding-top: 5px;
  }

  .highcharts-yaxis-labels div.xs-pl-30 {
    @media(max-width: '400px') {
      padding-left: 30px !important;
    }
  }
