.cfra-box-smooth-arrival {
    opacity: 0;
    animation: changeStyle 0.5s 0.2s forwards;

    @keyframes changeStyle {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
